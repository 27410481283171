.footer {
    width: 100%;
    position: fixed;
    bottom: 0;
    text-align: center;
    padding: 0;
    margin: 0;
    background-color: #F7F7F7;
    color: #000000;
    font-family: 'Montserrat';
}

.heart-footer-logo {
    width: 18px;
    height: 18px;
    margin: 0;
    padding: 10px 0;
    vertical-align: middle;
}

.footer-text {
    font-size: 12px;
    margin: 0;
    padding: 10px 0;
    line-height: 18px;
    color: #000000;
    vertical-align: middle;
    font-weight: 100;
}

.footer-text-left {
    padding-left: 10px;
}

.footer-text-right {
    padding-right: 10px;
}

.footer-bottom {
    border-top: 1px solid #E5E5E5;
}


/* The footer menu */

.footer-top {
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid #E5E5E5;
    color: #000000;
}

div a {
    list-style: none;
    margin: 0;
    padding: 10px 0;
    text-decoration: none;
    color: #000000;
    font-size: 12px;
    font-weight: 100;
    line-height: 18px;
    padding-left: 10px;
    padding-right: 10px;
}

div a:hover {
    color: #006699;
}
