.about-section {
    margin-right: 50px;
    margin-left: 50px;
    text-align: center; /* Center the content */
}

.about-title {
    font-weight: 300;
    font-size: 2rem;
    text-align: center;
}

.about-text {
    font-weight: 100;
    font-size: 1.4rem;
    text-align: justify;
}

.social-icons {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 20px; /* Space between icons */
}

.icon {
    font-size: 2.5rem; /* Adjust size as needed */
    color: #000; /* Adjust color as needed */
    transition: color 0.3s ease;
}

.icon:hover {
    color: #006699; /* Example hover color for LinkedIn */
}
