.privacy {
    padding-top: 190px;
    margin-top: 190px;
    margin: 150px;
}

.privacy-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    line-height: 2;
    font-weight: 500;
    font-size: 2rem;
    text-align: center;
}

.privacy-title-2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    line-height: 2;
    font-weight: 300;
    font-size: 1.8rem;
    text-align: center;
    margin-bottom: 0;
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 0;
}

.privacy-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    line-height: 2;
    font-weight: 100;
    font-size: 1.4rem;
    text-align: justify;
}

.privacy-text a {
    font-weight: 100;
    font-size: 1.4rem;
}

.quelle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    line-height: 2;
    font-weight: 100;
    font-size: 1.2rem;
    text-align: justify;
}

.quelle a {
    color: #000;
    text-decoration: none;
    font-weight: 100;
    font-size: 1.2rem;
}