.home {
    padding-top: 190px;
    margin-top: 190px;
    margin: 150px;
}

.job-title {
    font-size: 30px;
    font-weight: 100;
    margin: 0;
    padding: 0;
}

.job-title-text {
    border-bottom: #E5E5E5 solid 1px;
    color: #8a8a8a;
}

.hello {
    margin-top: 20px;
}

.hello-text {
    font-size: 40px;
    font-weight: 100;
    margin: 0;
    padding: 0;
    padding-right: 50px;
}

.home-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.home-container-right {
    margin: auto;
}

.image-right {
    width: 35rem;
    height: 50rem;
}