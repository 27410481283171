.impressum {
    padding-top: 190px;
    margin-top: 190px;
    margin: 150px;
}

.impressum-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    line-height: 2;
    font-weight: 300;
    font-size: 2rem;
    text-align: center;
}

.impressum-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    line-height: 2;
    font-weight: 100;
    font-size: 1.4rem;
    text-align: justify;
}

.quelle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    line-height: 2;
    font-weight: 100;
    font-size: 1.2rem;
    text-align: justify;
}

.quelle a {
    color: #000;
    text-decoration: none;
    font-weight: 100;
    font-size: 1.2rem;
}