.about {
    padding-top: 190px;
    margin-top: 190px;
    margin: 150px;
}

.about_down {
    padding-bottom: 190px;
    padding-left: 10%;
    padding-right: 10%;
    margin: 150px;
}

.grid-container {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr;
    grid-gap: 20px;
}

.about-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    line-height: 2;
    font-weight: 100;
}
