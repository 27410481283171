.header {
    max-width: 40%;
    width: 100%;
    margin: 10px auto;

    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    text-align: center;
    padding: 0;
    color: #000000;
    border: #E5E5E5;
    border-style: solid;
    border-width: 1px;
    border-radius: 20px;

    background-color: #F7F7F7;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.header-logo {
    width: 130px;
    height: 80px;
    padding-left: 30px;
    padding-right: 30px;
    vertical-align: middle;
}

.menu-text {
    display: inline-block;
    vertical-align: middle;
    font-size: 20px;
    font-weight: 100;
    margin: 0;
    padding: 10px 0;
    padding-left: 30px;
    padding-right: 30px;
}

div a {
    list-style: none;
    text-decoration: none;
}

div a:hover {
    color: #006699;
}
